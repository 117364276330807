import React, { useState, useEffect } from 'react'
import Home from '../../Home';
import { GETAllAPI, POSTAPI } from '../AllApi';
import { Card, Row, Col } from 'antd'
import appConfig from '../../../helpers/appConfig';
import './VendorList.css'
import history from '../../../history';
import VendorOrder from '../VendorOrder';

const VendorList = ( props) => {
  const [vendorData, setVendorData] = useState([]);
  const { Meta } = Card;

  useEffect(() => {
    getVendorList();
  }, []);


  let cartSize;


  useEffect(() => {
    localStorage.setItem("cartData", ([]))
    cartSize = localStorage.getItem("cartData") ;
    console.log("data==========",cartSize.length)
})






  const getVendorList = async () => {
    try {
      let resData = await GETAllAPI(`${appConfig.endpoint}/api/v1/vendor/apis/getVendors`)
      setVendorData(resData.data)
    } catch (error) {
      console.log(error)
    }
  }

let cityId = vendorData.cityId;
let cityDriveOut = vendorData.cityDriveOut;



  const displayVendors = (id, name, logo , cityId , cityDriveOut) => {
    localStorage.setItem("vid", id);
    localStorage.setItem("cityId", cityId);
localStorage.setItem("cityDriveOut" , cityDriveOut)

    // console.log("vendor id ====>",id)
    // console.log("vendor name ====>",name)
    // console.log("vendor logo ====>",logo)
    history.push({
      pathname: '/vendorOrders',
      state: { vid: id, name: name, logo: logo ,cartSize:cartSize , cityId:cityId , cityDriveOut:cityDriveOut  }
    })
  }

  console.log("vendor data ====>", vendorData)

  return (
    <Home>
      <div className="report-container text-center"
        style={{ marginBottom: "20px" }}
      >
        <span className="item">Vendors</span>
      </div>
      <Row gutter={16}>

      {
        //vendorData.length &&
          vendorData.map((e) => {
            console.log("e")
            let {city,state,country}=e.address
            return (
              <Col className="gutter-row" span={8}>
                <Card
                  className=' d-flex justify-content-between align-items-center card-data'
                  hoverable={true}
                  onClick={() => displayVendors(e._id, e.name, e.logo , e.cityId , e.cityDriveOut)}
                  size="small"
                  bordered={false}
                >
                  <Meta className="meta-tag flex-wrap text-center detail "
                    // title=<p className='mb-0'></p>
                    description={[
                      <div className='d-flex justify-content-between align-items-center' >
                        <div>
                          <img
                            alt="logo"
                            src={`${appConfig.s3url}/${e.logo}`}
                          />
                        </div>
                        <div className=' text-left d-flex flex-column justify-content-center align-items-start pt-2 pl-3'>
                          <span className='restaurantName'>{e.name}</span>
                          <span>Phone: {e.phoneNo}</span>
                          <span>{e.restaurantEmail}</span>
                          <span>{` Address: ${city} , ${state} ,${country}`}</span>
                          <span>Delivery Charges : {e.cityDriveOut}</span>
                        </div>
                      </div>
                    ]}
                  //description={e.restaurantEmail}
                  />
                </Card>
              </Col>
            )
          })}
      </Row>
      
    </Home>
  )
}

export default VendorList
