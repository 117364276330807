import React ,{useEffect,useState}from "react";
import { NavLink } from "react-router-dom";
import { Card, Col, Row, Descriptions, Divider ,Icon} from "antd";
import { AiOutlineCheckCircle, AiOutlineClose } from "react-icons/ai";
import "./PaymentSuccess.css";
import { Container } from "react-bootstrap";
// import { useLocation } from 'react-router';
import { GETAllAPI, POSTAPI } from "../AllApi";
import appConfig from "../../../helpers/appConfig";
const PaymentSuccess = (props) => {
const [resData,setResData]=useState(null);
const[paymentFailed , setPaymentFailed]= useState(false)
  const paramUrl = window.location.href;
  // console.log("paramUrl", paramUrl);
  const encUrl = new URL(paramUrl);
  const params = new URLSearchParams(encUrl.search);
  let merchant_reference =  params.get("merchant_reference");
  let transaction_id =  params.get("transaction_id");
  console.log("merchant_reference", merchant_reference);
  console.log("transaction_id", transaction_id);
  // merchant_reference = "65549b2ff0075f7942884952";
  // transaction_id = "T11114029.5k9KY8YxYNhkJKLGqZgGd7";

  //correct
  // merchant_reference=  "655c7e47e4af8b1563561663";
  //  transaction_id = "T11114029.5k9QjqfQNh5hE1NgtEjJRu";

  console.log("params",  params);

 

  const checkVendorPayment = async (data) => {
    let res = await POSTAPI(
      `${appConfig.endpoint}/api/v1/vendor/payment/orderPaymentCheckVendor`,
      data
    );
    if (res && res.status == 200) {
      console.log("res data createOrder ", res.data);
      setResData(res.data)
    } else {
      setPaymentFailed(true);



      console.log("res data payment check error=========== ", resData);
    }
  };
  
  useEffect(() => {
    let data = {
      id: merchant_reference,
      transaction_id,
    };
    checkVendorPayment(data);
  }, []);
  
  if(resData){
  const branchInfo = JSON.parse(localStorage.getItem("branchInfo"));
  const orderData = resData.items;
  const totalPrice = resData.totalPrice;
  const otp = resData.otp;
  const address = resData.customerDetail.address;
  const name = resData.customerDetail.name;
 // const logo = props.location.state.vendorLogo;
//  const name = props.location.state. resName;
  //const logo = ""
 // console.log("address======", address)
 console.log("res data=====",resData.customerDetail.name)

  const logo = localStorage.getItem("vendorlogo")
  return (

    <div className="bg-white">
      <Container className="bg-white p-5" id="displayrow">
        {/* <header className="homeLayout" style={{ marginBottom: "50px" }}>
          <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top justify-content-between">
            <NavLink
              className="navbar-brand d-position m-0 col-2"
              to="/dashboard"
            >
              <img
                src={logo === null ? "" : `${appConfig.s3url}/${logo}`}
                className="company-logo-header hide-logo p-0"
              />
              <span style={{ marginLeft: "5px" }}>
                <h5 className="m-0"> {name}</h5>
              </span>
              <img
                src={logo === null ? "" : `${appConfig.s3url}/${logo}`}
                className="company-logo-header2 hide-logo2"
              />
            </NavLink>
          </nav>
        </header> */}

        <Card className="m-5 p-3">
          <Row className="pb-3 d-flex justify-content-start align-items-center">
            <AiOutlineCheckCircle className="tickCheck" />
            <h3 className="heading-h3 mb-0">Order Placed Successfully</h3>
          </Row>
          <Row className="py-3 d-flex justify-content-start align-items-center">
            {" "}
            <p>Your OTP: {otp}</p>
          </Row>
          <Row className="pb-3 d-flex justify-content-start align-items-center">
            {" "}
            <p style={{ fontSize: "14px" }}>
              <i>
                We are delighted to confirm your recent food order placed with
                Tree Drive. You will receive your order confirmation through an
                email and SMS containing the OTP & provide this OTP at the time
                of receiving. DO NOT share this OTP with anyone else.
              </i>
            </p>
          </Row>
          <Row className="pb-3 d-flex justify-content-start align-items-center">
            {" "}
            <h5 className="headingSeparation w-100">Order Info</h5>
          </Row>
          <Row className="pb-3 d-flex flex-column justify-content-start align-items-center">
            {orderData.map((e) => {
              return (
                <Col className="d-flex justify-content-between w-100">
                  <p className="itemName">{e.name}</p>
                  <p className="itemName">Quantity: {e.quantity}</p>
                </Col>
              );
            })}
          </Row>
          <Row className="pb-3 d-flex justify-content-start align-items-center">
            <Col span={12}>
              <p className="itemName">Total:</p>
            </Col>
            <Col span={12} className="text-right">
              <p className="itemName">Kr. {Number(totalPrice).toFixed(2)}</p>
            </Col>
          </Row>
          <Row className="pb-3 d-flex justify-content-start align-items-center">
            {" "}
            <h5 className="headingSeparation w-100">User Info</h5>
          </Row>
          <Row className="pb-3 d-flex justify-content-start align-items-center">
            {" "}
            <Col span={12}>
              <p className="itemName">Address:</p>
            </Col>
            <Col span={12} className="text-right">
              <p className="itemName">{address}</p>
            </Col>
          </Row>{" "}
          <Divider />
          <Row className="pb-3 d-flex justify-content-start align-items-center">
            {" "}
            <NavLink to="/vendor-list" className="orderBtn">
              Explore Vendors
            </NavLink>
          </Row>
        </Card>
        {/* </Row> */}
      </Container>
    </div>
  );
  }
  
if(resData==null && !paymentFailed){
  return  <div className="bg-white">
  <Container className="bg-white payment" id="displayrow">
  <div
        className="d-flex justify-content-center p-3"
        style={{ height: "100vh" }}
      >
          <Row>
            <Col
              span={24}
              style={{
                textAlign: "center",
                padding: "90px",
                color: "white"
              }}
            >
              <h3 className="loading bg-dark">Loading Please wait......</h3>
            </Col>
          </Row>
      </div>
    
  </Container>
</div>
}
if(paymentFailed){
  return  <div className="bg-dark">
  <Container className="bg-dark payment" id="displayrow">

  <div
        className="d-flex justify-content-center p-5"
        style={{ height: "100vh" }}
      >
          <Row>
            <Col
              span={24}
              style={{
                textAlign: "center",
                padding: "50px",
                color: "white"
              }}
            >
            <div className="loading bg-white">
            <Icon type="close-circle" style={{ color: 'red', fontSize:"60px" }} />
            <h2 className="pt-4">Oops!! Something went wrong</h2>
              <h3>Your payment failed...</h3>
              {/* <h4>Please try again</h4> */}
              <Row className="pt-5 d-flex align-items-center">
            {" "}
            <NavLink to="/vendorOrders" className="payCancel-btn">
              Try Again
            </NavLink>
          </Row>
              </div>
            </Col>
          </Row>
      </div>
    
  </Container>
</div>
}
};

export default PaymentSuccess;
