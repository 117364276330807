import React from "react";
import AryanLogo from "../../assets/AryanLogo.png";
import table1 from "../../assets/table1.png";
import tree from "../../assets/tree.png";
import web from "../../assets/web.png";
import appConfig from "../../helpers/appConfig";
import { Icon as RIcon } from "react-icons-kit";
import { NavLink } from "react-router-dom";
import { radioChecked } from "react-icons-kit/icomoon";
const FloatingBtn = () => {
  const slug = localStorage.getItem("slug");
  const empType = localStorage.getItem("empType");
  const branchType = localStorage.getItem("branchType");
  const isVendorAllowed = localStorage.getItem("isVendorAllowed");
  console.log("------------branchType right",branchType)
 //console.log("-----------isVendorAllowed--------",isVendorAllowed)
  return (
    <div className="right-boxs">
      <ul className="list-group">
        {
          branchType=="rest"? <li>
          <NavLink to="/admin">
            <img src={AryanLogo} />
            <p className="title">A-Board</p>
          </NavLink>
        </li>:""
        }
       { branchType=="rest" && isVendorAllowed == "true" ? <li>
          <NavLink to="/vendor-list">
            <img src={AryanLogo} />
            <p className="title">Vendor</p>
          </NavLink>
        </li>:"" }
         

{ (localStorage.getItem("sadm")) == "true"  && (
          <li>
            <NavLink to="/OpprettKontoOld">
              <RIcon icon={radioChecked} size={45} />
              <p className="title">Opprett konto old</p>
            </NavLink>
          </li>
          )}

{ (localStorage.getItem("sadm")) == "true"  && (
            <li>
              <NavLink to="/OpprettKonto">
                <RIcon icon={radioChecked} size={45} />
                <p className="title">Opprett konto</p>
              </NavLink>
            </li>
          )}
        {Number(localStorage.getItem("tableview")) === 1 && (
          <li>
            <NavLink to="/table">
            <img src={table1}  />
              <p className="title">Bord</p>
            </NavLink>
          </li>
        )}
        <li>
          <NavLink to="/arkiv/tfl-rapport">
          <img src={tree}  />
            <p className="title">Tree For Life</p>
          </NavLink>
        </li>
        <li>
          <a href={`${appConfig.publicURL}/${slug}`} target="_blank">
            <img src={web}  />
            <p className="title">Web</p>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default FloatingBtn;
