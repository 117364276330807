import React, { useState, useEffect } from "react";
import Home from '../../Home';
import { Card, Row, Col, Button } from "react-bootstrap";
import { DatePicker, Table, Statistic, Empty } from "antd";
import moment from 'moment';
import { Chart } from "react-google-charts";
import { POSTAPI } from '../../Vendor/AllApi';
import appConfig from '../../../helpers/appConfig';
const { MonthPicker , WeekPicker} = DatePicker;

const TopSellingPrd = (props) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
  const [lineChartData, setLineChartData] = useState([]);
  const [lineChartData2, setLineChartData2] = useState([]);
  const [graphDate, setGraphDate] = useState("");
    const bid = localStorage.getItem("bid");
    const token = localStorage.getItem("token");

    // useEffect(() => {
    //     //setLoading(true);
    //     filterData(moment(new Date()), "d");
    //   }, []);


      useEffect(()=>{
        let date = new Date();
        let startDate = moment(date).startOf("month").add(1, "days")._d;
        let endDate =moment(date).endOf("month").add(1, "days")._d;
        let branchId = bid;
        let data = {};
        data.startDate = startDate;
        data.endDate = endDate;
        data.branchId = bid;
    
        productData(data);
    
        console.log("intial data =====", startDate , endDate , branchId);
    
      },[])

      const productData = async (postdata) => {
        const headers = {
          "x-auth-token": token,
        };
        try {
          let resData = await POSTAPI(
            `${appConfig.endpoint}/api/v1/employee/invoice/TopSellingProducts`,
            postdata,
            {
              headers: headers,
            }
            
          );
          if( resData.data.topselling15.length > 0 || resData.data.topselling25.length > 0){
                  
              let d1 = resData.data.topselling15[0].top_selling_products;
              let d2 =resData.data.topselling25[0].top_selling_products;
              
              let dataArray = [["Produktnavn", "Mengde (i gm/liter)"]];
              //console.log("dd", d)
              
              d1.map((i)=>{
    
                let t = [];
                let productName = i.productName;
                let totalQuantity = i.totalQuantity;
                t = [productName, totalQuantity];
                dataArray.push(t);
    
              })
              setLineChartData(dataArray);

              let dataArray2 = [["Produktnavn", "Mengde (i gm/liter)"]];
              //console.log("dd", d)
              
              d2.map((i)=>{
                let t1 = [];
                let productName1 = i.productName;
                let totalQuantity1 = i.totalQuantity;
                t1 = [productName1, totalQuantity1];

                dataArray2.push(t1);
              })


              setLineChartData2(dataArray2);
              setGraphDate(resData.BeDate);
    
        
    
          }else{
            setLineChartData([]);
            setLineChartData2([]);
            setGraphDate("");
          }
    
        } catch (error) {
          setData([]);
          console.log(error);
          setGraphDate("");
        }
      };
    
      const filterData = (date, type) => {
        let postdata = {};
    
        switch (type) {
          case "m":
            postdata = {
              startDate: moment(date).startOf("month").add(1, "days"),
              endDate: moment(date).endOf("month").add(1, "days"),
              branchId: bid,
            };
            break;
            case "w":
              postdata = {
                startDate :moment(date).startOf("week").add(2, "days"),
                endDate : moment(date).endOf("week").add(2, "days"),
                branchId: bid,
              };
              break;
          case "d":
            postdata = {
              startDate: moment(date),
              endDate: moment(date).add(1, "days"),
              branchId: bid,
            };
        }
        productData(postdata);
        console.log(postdata);
      };


 
    const options = {
        title: "Mest solgte produkter (avgift 15%)",
        // vAxis: { title: "Total Quantity" },
        // hAxis: { title: "Product Name" },
        vAxis: { title: "Produktnavn" , 
        textStyle : {
          fontSize: 12 // or the number you want
      }},
        hAxis: { title: "Mengde (i gm/liter)",  },
        textStyle : {
          color: "#000",
            fontName: "sans-serif",
            fontSize: 11,
            bold: true,
            italic: false
      },
        titleTextStyle: {
          fontSize: 18, // 12, 18 whatever you want (don't specify px)
      },
      //  height: 800,
     //  width: 800,
        bar: { groupWidth: "60%" },
        legend: { position: "none" },
        seriesType: "bars",
        series: { 5: { type: "line" } },
        colors: ['#e0440e', '#e6693e', '#ec8f6e', '#f3b49f', '#f6c7b6'],
        animation: {
          startup: true,
          duration: 1000,
          easing: "out",
        },
      };

      const options2 = {
        title: "Mest solgte produkter (avgift 25%)",
        // vAxis: { title: "Total Quantity" },
        // hAxis: { title: "Product Name" },
        vAxis: { title: "Produktnavn" , 
        textStyle : {
          fontSize: 12 // or the number you want
      }},
        hAxis: { title: "Mengde (i gm/liter)",  },
        textStyle : {
          color: "#000",
            fontName: "sans-serif",
            fontSize: 11,
            bold: true,
            italic: false
      },
        titleTextStyle: {
          fontSize: 18, // 12, 18 whatever you want (don't specify px)
      },
        //height: 800,
      //  width: 800,
        bar: { groupWidth: "60%" },
        legend: { position: "none" },
        seriesType: "bars",
        series: { 5: { type: "line" } },
        legend: 'none',
        animation: {
          startup: true,
          duration: 1000,
          easing: "out",
        },
      };

    const goBackMainPage = () => {
        props.history.push("/arkiv");
      };

  return (
    <Home>
         <div className="report-container">
            <span className="item" style={{ width: "60%" }}>
              <span id="less-visible">Arkiv :  </span>
               Grafisk gjengivelse av Produkt Rapport
            </span>

            <span id="less-visible">
              <Button
                onClick={() => goBackMainPage()}
                className="ant-btn back-btn"
              >
                <i className="fa fa-arrow-circle-left" aria-hidden="true" />
              </Button>
            </span>
          </div>
         <>
         <Card className='mb-4'>
      <Card.Header className="h4">Oversikt over mest kjøpte varer</Card.Header>
      </Card>
         <div className="d-flex ">
          <div className='row' style={{marginLeft: "100px"}}>
          <DatePicker onChange={(e) => filterData(e, "d")} />
          </div>
          <div className='row' style={{marginLeft: "100px"}}>
          <WeekPicker
            onChange={(e) => filterData(e, "w")}
            placeholder="Select week"
          />
          </div>
          <div className='row' style={{marginLeft: "100px"}}>
          <MonthPicker
                placeholder="Select Month"
                onChange={(e) => filterData(e, "m")}
              />
          </div>
        </div>
         </>
        <div>

       
       {
        lineChartData.length > 0 ?

       <>
       <div className="d-flex justify-content-center pt-5">
              <span className="heading2"> {moment(graphDate.startDate).format('MMM DD, YYYY')} to {moment(graphDate.endDate).format('MMM DD, YYYY')}</span>

              </div>
        <div  style={{marginTop: "10px"}}>
        <Chart
    //  chartType="ColumnChart"
    chartType="BarChart"
      width="100%"
      height="400px"
      data={lineChartData}
      options={options}
    /> 
        </div>
       </>
        :
   <div style={{marginTop: "50px"}}>
   <Empty />
   </div>
       }
        </div>
       <div>
        {
          lineChartData2.length > 0 ?
          <div className='row' style={{marginTop: "100px",marginBottom: "100px"}}>
        <Chart
   //  chartType="ColumnChart"
   chartType="BarChart"
      width="100%"
      height="400px"
      data={lineChartData2}
      options={options2}
    />
        </div> :
      ""
        }
       </div>
    </Home>
  )
}

export default TopSellingPrd
