import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getAnsatte,
  editAnsatte,
  addAnsatte,
  deleteAnsatte
} from "../../action/ansatteAction";
import { Card, Row, Col, Table as Tabl, Button } from "react-bootstrap";
import Home from "./../Home";
import { Table, Tag, Popover, Input, InputNumber, Form, Switch } from "antd";
import appConfig from "../../helpers/appConfig";
import Modal from "react-modal";
import AddForm from "./AddForm";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

const FormItem = Form.Item;
const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);
class EditableCell extends Component {
  getInput = () => {
    if (this.props.inputType === "number") {
      return <InputNumber />;
    }
    return <Input />;
  };

  render() {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      ...restProps
    } = this.props;
    return (
      <EditableContext.Consumer>
        {(form) => {
          const { getFieldDecorator } = form;
          return (
            <td {...restProps}>
              {editing ? (
                <FormItem style={{ margin: 0 }}>
                  {getFieldDecorator(dataIndex, {
                    rules: [
                      {
                        required: true,
                        message: `Please Input ${title}!`
                      }
                    ],
                    initialValue: record[dataIndex]
                  })(this.getInput())}
                </FormItem>
              ) : (
                restProps.children
              )}
            </td>
          );
        }}
      </EditableContext.Consumer>
    );
  }
}
class Index extends Component {
  constructor(props) {
    super(props);
    var kitchenpermission = localStorage.getItem("kitchenpermission");
    var barpermission = localStorage.getItem("barpermission");
    this.state = {
      error: null,
      isLoaded: false,
      isAuthenticated: false,
      modalIsOpen: false,
      modalAddIsOpen: false,
      visiblePopover: false,
      deletingKey: "",
      currentPage: "empTable",
      first_name: "",
      last_name: "",
      address: "",
      items: "",
      editingKey: "",
      currentEmp: "",
      // permissions: [
      //   "taccountant",
      //   // "managecat",
      //   "crm",
      //   // "hrm",
      //   "stock",
      //   "invoice",
      //   // "openaccount",
      //   "openaccountWeb",
      //   "tableview",
      //   "tdDiscountPermission"
      // ],
      permissions: props.permissions? props.permissions:[],
      taccountant: 0,
      managecat: 0,
      crm: 0,
      hrm: 0,
      stock: 0,
      invoice: 0,
      openaccount: 0,
      openaccountWeb: 0,
      tableview: 0,
      kitchen: 0,
      bar: 0,
      tdDiscountPermission:0,
      empType: localStorage.getItem("empType"),
      modalViewIsOpen: false
    };
    // if (kitchenpermission) {
    //   this.setState({ permissions: this.state.permissions.push("kitchen") });
    // }
    // if (barpermission) {
    //   this.setState({ permissions: this.state.permissions.push("bar") });
    // }
    this.toggleModal = this.toggleModal.bind(this);

    this.empColumns = [
      {
        title: "Navn",
        width: "12%",
        key:"Navn20%##",
        editable: false,
        dataIndex: "name",
        render: (item, record) => {
          if (item !== " ") {
            return (
              <a
                style={{ color: "#1890ff" }}
                onClick={() => this.showEmployeeProfile(record)}
              >
                {record.firstName+" "+record.lastName}
               
              </a>
            );
          } else {
            return <center>--</center>;
          }
        }
      },
      {
        title: "Email",
        dataIndex: "email",
        key:"Email26%##",
        editable: false,
        width: "15%",
        render: (item, record) => (
          <a
            style={{ color: "#1890ff" }}
            onClick={() => this.showEmployeeProfile(record)}
          >
            {item}{" "}
            {record.empType == "MANAGER" ? (
              <p style={{ color: "green" }}>[MANAGER]</p>
            ) : (
              ""
            )}
          </a>
        )
      },
      {
        title: "Tillatelser",
        editable: false,
        key:"TillatelserFalse#",
        width: "35%",
        className:"tag-ansatee",
        render: (text) => {
          let roles = [];
          text.hrm && roles.push(<Tag color="green">ANSATTE</Tag>);
          text.crm && roles.push(<Tag color="blue">KUNDER</Tag>);
          text.taccountant && roles.push(<Tag color="pink">ARKIV</Tag>);
          text.managecat && roles.push(<Tag color="purple">MENY</Tag>);
          text.invoice && roles.push(<Tag color="cyan">FAKTURA</Tag>);
          text.stock && roles.push(<Tag color="lime">LAGER</Tag>);
          text.openaccount && roles.push(<Tag color="gold">OPPRETT KONTO</Tag>);
          text.openaccountWeb && roles.push(<Tag color="brown">OPPRETT WEB</Tag>);
          text.tableview && roles.push(<Tag color="geekblue">BORD</Tag>);
          text.kitchen && roles.push(<Tag color="volcano">KJØKKEN</Tag>);
          text.bar && roles.push(<Tag color="orange">BAR</Tag>);
          text.tdDiscountPermission &&
            roles.push(<Tag color="orange">TD DISCOUNT</Tag>);
          return roles;
        }
      },
      {
        title: "Edit",
        dataIndex: "operation",
        key:"EditOperation#",
        width: "8%",
        render: (text, record) => {
          const editable = this.isEditing(record);
          const empType = localStorage.empType;
          let isAdminLogged=localStorage.getItem("sadm")
        //  console.log("------isAdminLogged-------------",isAdminLogged)
         if (isAdminLogged=="true" || empType=="MANAGER") {
          return (
            <a
                style={{
                  color: "#1890ff",
                  borderColor: "blue",
                  borderWidth: 2,
                  padding: 5
                }}
                onClick={() => this.toggleModal(record)}
              >
                EDIT
              </a>
            
          );
        }
         else if (empType=="EMPLOYEE") {
            return (
              <center>--</center>
            );
          } else if((record.empType !== "MANAGER" )){
            return (
              <a
                style={{
                  color: "#1890ff",
                  borderColor: "blue",
                  borderWidth: 2,
                  padding: 5
                }}
                onClick={() => this.toggleModal(record)}
              >
                Edit
              </a>
            );
          } else {
            return <center>--</center>;
          }
        }
      },
      {
        title: "SLETT",
        width: "8%",
        key:"SLETT#$",
        // key: 'action',
        render: (text, record) => {
          const deletable = this.isDeleting(record);
          const empType = localStorage.getItem("empType");
          let isAdminLogged=localStorage.getItem("sadm")
          if (isAdminLogged=="true" || empType=="MANAGER") {
            return (
              <>
                {deletable ? (
                  <Popover
                    content={
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-around"
                        }}
                      >
                        <a
                          style={{ color: "#1890ff" }}
                          onClick={this.hideDelete}
                        >
                          AVBRYT
                        </a>
                        <a
                          style={{ color: "#F44336" }}
                          onClick={() => this.delete(record)}
                        >
                          SLETT
                        </a>
                      </span>
                    }
                    title="Are you Sure?"
                    trigger="click"
                    visible={this.state.visiblePopover}
                    onVisibleChange={this.handleVisibleChange}
                  >
                    <a
                      onClick={() => this.setState({ popoverId: record.id })}
                      style={{ color: "#F44336" }}
                    >
                      SLETT
                    </a>
                  </Popover>
                ) : (
                  <a
                    onClick={() => this.deleteTemp(record.email)}
                    style={{ color: "#F44336" }}
                  >
                    SLETT
                  </a>
                )}
              </>
            );
          } else if ((record.empType !== "MANAGER" ) && empType!=="EMPLOYEE") {
            return (
              <>
                {deletable ? (
                  <Popover
                    content={
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-around"
                        }}
                      >
                        <a
                          style={{ color: "#1890ff" }}
                          onClick={this.hideDelete}
                        >
                          AVBRYT
                        </a>
                        <a
                          style={{ color: "#F44336" }}
                          onClick={() => this.delete(record)}
                        >
                          SLETT
                        </a>
                      </span>
                    }
                    title="Are you Sure?"
                    trigger="click"
                    visible={this.state.visiblePopover}
                    onVisibleChange={this.handleVisibleChange}
                  >
                    <a
                      onClick={() => this.setState({ popoverId: record.id })}
                      style={{ color: "#F44336" }}
                    >
                      SLETT
                    </a>
                  </Popover>
                ) : (
                  <a
                    onClick={() => this.deleteTemp(record.email)}
                    style={{ color: "#F44336" }}
                  >
                    SLETT
                  </a>
                )}
              </>
            );
          } else {
            return <center>--</center>;
          }
        }
      }
    ];
  }
  addToggleModal = (e, isS) => {
    this.setState({ modalAddIsOpen: !e });
    if (isS) {
      this.setState({ isLoaded: true });
      this.props.getAnsatte().then(() => this.setState({ isLoaded: false ,permissions:this.props.permissions?this.props.permissions:[]}));
    }
  };
  delete = (record) => {
    const id = record.id;
    this.props.deleteAnsatte({ id }).then(() => {
      this.setState({ isLoaded: true, visiblePopover: false });
      this.props.getAnsatte().then(() => this.setState({ isLoaded: false }));
    });
  };
  handleVisibleChange = () => {
    this.setState({ visiblePopover: true });
  };
  deleteTemp(key) {
    this.setState({ deletingKey: key });
  }
  showEmployeeProfile = (employee) => {
    this.setState({
      modalViewIsOpen: !this.state.modalViewIsOpen,
      currentEmp: employee
    });
  };
  isEditing = (record) => {
    return record.id === this.state.editingKey;
  };
  isDeleting = (record) => {
    return record.email === this.state.deletingKey;
  };

  hideDelete = () => {
    this.setState({ deletingKey: "" });
  };
  toggleViewModal = () => {
    this.setState({ modalViewIsOpen: !this.state.modalViewIsOpen });
  };
  toggleModal(record) {
    const {
      crm,
      hrm,
      invoice,
      taccountant,
      managecat,
      stock,
      openaccount,
      openaccountWeb,
      tableview,
      kitchen,
      bar,
      tdDiscountPermission
    } = record;
    this.setState({
      crm,
      hrm,
      taccountant,
      invoice,
      managecat,
      stock,
      openaccount,
      openaccountWeb,
      tableview,
      kitchen,
      bar,
      tdDiscountPermission,
      modalIsOpen: !this.state.modalIsOpen,
      editingKey: record.email
    });
  }
  componentDidMount() {
    this.setState({ isLoaded: true });
    this.props.getAnsatte().then(() => this.setState({ isLoaded: false ,permissions:this.props.permissions?this.props.permissions:[]}));
  }
  changed = (checked, item) => {
    let toggledState = {};
    if (checked) {
      toggledState[item] = 1;
    } else {
      toggledState[item] = 0;
    }
    this.setState(toggledState);
  };
  editEmployee = (e) => {
    e.preventDefault();
    const data = {
      email: this.state.editingKey,
      hrm: this.state.hrm,
      crm: this.state.crm,
      taccountant: this.state.taccountant,
      invoice: this.state.invoice,
      stock: this.state.stock,
      managecat: this.state.managecat,
      openaccount: this.state.openaccount,
      openaccountWeb: this.state.openaccountWeb,
      tableview: this.state.tableview,
      kitchen: this.state.kitchen,
      bar: this.state.bar,
      tdDiscountPermission: this.state.tdDiscountPermission
    };
    this.props.editAnsatte(data).then(() => {
      this.setState({ isLoaded: true, modalIsOpen: false });
      this.props.getAnsatte().then(() => this.setState({ isLoaded: false }));
    });
  };
  renderF() {
    const { error, items, isLoaded, permissions, currentPage, currentEmp } =
      this.state;

    //label for edit button edit employee permission
    var kitchenpermission = localStorage.getItem("kitchenpermission");
    var barpermission = localStorage.getItem("barpermission");
    console.log(this.state);
    const regex = /(^(.)|png|jpeg|jpg)$/;
    const permissionsAlt = {
      managecat: "MENY",
      taccountant: "ARKIV",
      stock: "LAGER",
      crm: "KUNDER",
      hrm: "ANSATTE",
      invoice: "FAKTURA",
      openaccount: "OPPRETT KONTO",
      openaccountWeb: "OPPRETT WEB",
      tableview: "BORD",
      tdDiscountPermission: "TD DISCOUNT"
    };
    if (kitchenpermission) {
      permissionsAlt.kitchen = "KJØKKEN";
    }
    if (barpermission) {
      permissionsAlt.bar = "BAR";
    }

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };

    const empColumns = this.empColumns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.dataIndex === "price" ? "number" : "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record)
        })
      };
    });
    return (
      <>
        <Card className="text-right">
          <Card.Body>
            <Button
              onClick={() =>
                this.addToggleModal(this.state.modalAddIsOpen, false)
              }
              className="btn-ab"
            >
              LEGG TIL ANSATTE
            </Button>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Table
              loading={isLoaded}
              dataSource={this.props.ansatte}
              columns={empColumns}
              rowKey={(record) => record.id}
              components={components}
              bordered
              rowClassName="editable-row"
              scroll={{
                y: 500,
              }}
            />
            <Modal
              isOpen={this.state.modalIsOpen}
              onAfterOpen={this.afterOpenModal}
              onRequestClose={this.toggleModal}
              style={customStyles}
              ariaHideApp={false}
              contentLabel="LEGG TIL ANSATTE"
            >
              <div className="button-container">
                <button onClick={this.toggleModal} className="close-button">
                  X
                </button>
              </div>
              <form
                className="add-employee"
                onSubmit={(e) => this.editEmployee(e)}
              >
                <li key="1">
                  <center>
                    <h2>LEGG TIL ANSATTE</h2>
                  </center>
                </li>
                <li key="2">
                  <input
                    type="text"
                    onChange={this.handleChange}
                    name="email"
                    className="input2"
                    placeholder="E-mail"
                    value={this.state.editingKey}
                    disabled
                  />
                </li>
                <li key="3" className="designation-title">ANSATEE TILLATELSER</li>
                <li key="4" className="switches">
                  {permissions.map((item) => {
                    return (
                      <Switch
                        checkedChildren={permissionsAlt[item]}
                        unCheckedChildren={permissionsAlt[item]}
                        checked={this.state[item]}
                        onChange={(checked) => this.changed(checked, item)}
                      />
                    );
                  })}
                </li>
                <li key="5">
                  <button type="submit" id="submit-landing2">
                    SEND
                  </button>
                </li>
              </form>
            </Modal>
            <Modal
              isOpen={this.state.modalViewIsOpen}
              onRequestClose={this.toggleViewModal}
              onAfterOpen={this.afterOpenModal}
              style={customStyles}
              ariaHideApp={false}
              contentLabel="ANSATTE PROFIL"
            >
              <Card>
                <Card.Body>
                  <Row>
                    <Col className="text-right">
                      <button onClick={this.toggleViewModal} className="btn">
                        X
                      </button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Tabl striped bordered hover>
                        <tr>
                          <th>Navn</th>
                          <td>{currentEmp.name || "-"}</td>
                        </tr>
                        <tr>
                          <th>Email</th>
                          <td>{currentEmp.email}</td>
                        </tr>
                        <tr>
                          <th>Tlf. Nr.</th>
                          <td>{currentEmp.phoneNo}</td>
                        </tr>
                        <tr>
                          <th>Adresse.</th>
                          <td>
                            {currentEmp.address && currentEmp.address.line1}{" "}
                            {currentEmp.address && currentEmp.address.line2}
                            <br />
                            {currentEmp.address && currentEmp.address.city}{" "}
                            {currentEmp.address && currentEmp.address.pin}
                            <br />
                            {currentEmp.address &&
                              currentEmp.address.state}{" "}
                            {currentEmp.address && currentEmp.address.country}
                          </td>
                        </tr>
                      </Tabl>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <span>
                        <h5>Dokumenter</h5>
                      </span>
                      {currentEmp.personalInfo &&
                      currentEmp.personalInfo.length > 0
                        ? currentEmp.personalInfo.map((item, index) => {
                            if (item.match(regex)) {
                              return (
                                <div>
                                  Dokumenter {index + 1}:
                                  <img
                                    style={{ maxWidth: "100%" }}
                                    src={`${appConfig.endpoint}/${item}`}
                                    key={item}
                                  />
                                </div>
                              );
                            } else {
                              return (
                                <div key={item}>
                                  <a href={`${appConfig.endpoint}/${item}`}>
                                    Dokumenter {index + 1}
                                  </a>
                                  <br />
                                </div>
                              );
                            }
                          })
                        : "-"}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Modal>
          </Card.Body>
        </Card>
        <AddForm
          modalAddIsOpen={this.state.modalAddIsOpen}
          addToggleModal={this.addToggleModal}
          addAnsatte={this.props.addAnsatte}
          permissions={permissions?permissions:[]}
        />
      </>
    );
  }
  render() {
    return (
      <Home>
        <div className="report-container text-center">
          <span className="item">Ansatte</span>
        </div>
        {this.renderF()}
      </Home>
    );
  }
}
const mapStateToProps = (state) => {
    let permissions=state.ansatte.empPermissions
    console.log("---------STATE---permissions---",permissions)
  return {
    ansatte:state.ansatte.employeeList,
    permissions:permissions  
  };
};
export default connect(mapStateToProps, {
  getAnsatte,
  editAnsatte,
  addAnsatte,
  deleteAnsatte
})(Index);
